import * as React from "react";
import Layout from "../components/common/layout";
import ContactUsThankYou from "../components/contact-us-thank-you/ContactUsThankYou";

const ContactThankYou = () => {
  return (
    <Layout>
      <ContactUsThankYou />
    </Layout>
  );
};

export default ContactThankYou;
