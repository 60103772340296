import React from 'react'
import Button from '../common/button'

const ContactUsThankYou = () => {
  return (
     <section className="flex flex-col justify-center items-center gap-6 w-full px-8 pt-20 pb-24 min-h-[50vh]">
      <h1 className="dx-h1 text-center">お問い合わせを受け付けました。</h1>
      <div className="text-center dx-p1">
        <p>後ほど担当者よりご連絡いたします。</p>
      </div>
      <Button to='/'><span className='leading-6'>ホームページに戻る</span></Button>
    </section>
  )
}

export default ContactUsThankYou;